import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.theme.mediaQueries.mobile} {
    padding-inline: ${p => p.theme.padding.mobileBlock}px;
  }

  img {
    transition: all ${p => p.theme.transitions.default};
    filter: ${p => p.theme.colors.iconCarrierFilter};
    opacity: ${p => p.theme.opacity.normalIcon};
    cursor: pointer;
    object-fit: contain;

    &:hover {
      filter: grayscale(0%);
      opacity: 1;
    }

    ${p => p.theme.mediaQueries.mobile} {
      height: 32px;
      width: 100%;
    }
  }
`

export const List = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1014px;

  ${p => p.theme.mediaQueries.mobile} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
`
